import React from 'react';
import './Footer.scss';
import { LogoImage } from '../../components/LogoImage/LogoImage';

function Footer({
  contact,
  email
}) {
  return (
    <footer>
      <div className = 'footer__logo'>
        <LogoImage isFooter={true} />
      </div>
      <div className='footer__contact'>
        <p>{contact}</p>
        <p>{email}</p>
      </div>
    </footer>
  );
}

export default Footer;