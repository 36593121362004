import React from "react";

export const LogoImage = ({isFooter}) => {
    let logoPath = '/tradesaillogo.svg';
    if(isFooter) {
        logoPath = '/logo-footer.svg'
    } 
    return (
        <img src={logoPath} alt= 'CS'/>
    );
}