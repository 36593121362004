import React from 'react';
import './YoutubeEmbed.scss';

function YoutubeEmbed({
  embedId
}) {
  return (
    <>
      <div className='video-responsive'>
        <iframe
          id="ytb-frame-embbed"
          width='853'
          height='480'
          src={`https://www.youtube-nocookie.com/embed/${embedId}`}
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
          title='Embedded youtube'
        />
      </div>
    </>
  );
}

export default YoutubeEmbed;
