 
 import React from "react";
 import './TalkSpecialistButton.scss'

 const BTN_STYLES = ['btn-talk--primary', 'btn-talk--secondary_page', 'btn-talk--tertiary']

 export const TalkSpecialistButton = ({btnStyle, btnText, refFunction, functionParam}) => {
     const buttonStyle = BTN_STYLES.includes(btnStyle) ? btnStyle : BTN_STYLES[0];

     return (
        <button
            className={`${buttonStyle} btn-talk-font`} 
            onClick = {() => { refFunction ? refFunction(functionParam) : refFunction() }}
            type='button'>
                {btnText}
        </button>
     )
 }