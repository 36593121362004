
import React from 'react';
import './InfoSection.scss';

function InfoSection({
  headline,
  description,
  backgroundImg,
  imgStart
  
}) {
  return (
    <>
      <div className = {imgStart ? 'info__section__left' : 'info__section__right'}
      style = {{ backgroundImage: `url(${backgroundImg})`}}> 
        <div className = {imgStart ? 'info__container__right' : 'info__container__left'}>
          <div className = 'info__text'>
            <h1 className = 'info__title'>
              {headline}
            </h1>
            <p className = 'info__description'>
              {description}
            </p>
          </div>
        </div>
      </div> 
    </>
  );
}

export default InfoSection;