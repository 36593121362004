import React from 'react';
import './App.scss';
import Home from './pages/HomePage/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Footer from './pages/Footer/Footer';
import { footerObj } from './pages/HomePage/Data';

function App() {
  return (
    <Router>
      <Switch>
        <Route path='/' exact component={Home} />
      </Switch>
      <Footer {...footerObj}/>
    </Router>
  );
}

export default App;