
  
  export const infoPageObj01 = {
    headline: 'Fast and Flexible',
    description:
      'Join the trade finance marketplace in weeks not months with our subscription-based platform that can grow with your team.',
    imgStart: true,
    backgroundImg: '/infoitem01.png'
  };

  export const infoPageObj02 = {
    headline: 'Regional Bank Ready',
    description:
      'We make it possible for any bank to grow their business with a low-cost subscription based model based on your needs.',
    buttonLabel: 'Learn More',
    imgStart: false,
    backgroundImg: '/infoitem02.png'
  };
  
  export const infoPageObj03 = {
    headline: 'Future-Proof Security',
    description:
      'Combined with Microsoft Azure Financial Services Cloud we have the infrastructure and security to take customer experiences to the next level.',
    imgStart: true,
    backgroundImg: '/infoitem03@3x.png', 
  };

  export const coverPageObj = {
    title: 'Introducing TradeSail',
    subtitle: 'Powered By Eximbills',
    description: 'A world of possibilities in cross-border financing .',
    headerButtonLabel: 'Schedule a Demo',
    buttonLabel: 'Talk to a Specialist',
    backgroundImg: '/coverbackground.png'
  }

  export const videoPageObj = {
    headline: 'Get to Know Us',
    description: '',
    buttonLabel: 'Talk to a Specialist'
  }

  export const planPageObj = {
    headline: 'Choose the Plan that\'s Right for You',
    description: 'TradelSail\'s pricing plans are simple, transparent, and perfect for banks of any size. \nNo surprise fees or extra charges. Pay only for what you need.',
    buttonLabel: 'Talk to a Specialist',
    selectedPlan: 'Tier 2'
  }

  export const footerObj = {
    contact: 'Get in touch!',
    email: process.env.REACT_APP_EMAIL_CONTACT
  }

  export const calendlyObj = {
    prefill: '',
    pageSettings: {
      'backgroundColor': 'fff',
      'hideEventTypeDetails': false,
      'hideLandingPageDetails': false,
      'primaryColor': '00a2ff',
      'textColor': '4d5055',
      'hideGdprBanner': true
    },
    utm: '',
  }

  export const videoSectionObj = {
    embedId: process.env.REACT_APP_VIDEO_EMBED_ID
  }

  export const calendlyUrlObj = {
    url: process.env.REACT_APP_CALENDLY_URL
  }

  export const formSectionObj = {
    portalId: process.env.REACT_APP_HUBSPOT_PORTAL_ID,
    formId: process.env.REACT_APP_HUBSPOT_FORM_ID,
    formTitle: 'Tell Us About Yourself'
  }

  export const footerSectionObj = {
    headline: 'From the makers of Eximbills, the most trusted trade finance system in the world',
    description: 'With nearly 40 years of trade finance software expertise, China Systems is proud to bring the power of Eximbills to small and mid-sized banks. Discover everything our new subscription-based trade finance automation platform can do for your bank.',
    image: '/footer-image.svg'
  }

  const BILLING_TYPES = ['monthly', 'annually']
  const PRICE_PERIODS = ['month', 'year']

  class Plan {
    constructor(planId, planName, description, billingType, price, pricePeriod, benefits=[], released, releaseDate, contactPhone) {
      this.planId = planId;
      this.planName = planName;
      this.description = description;
      this.billingType = BILLING_TYPES.includes(billingType) ? billingType : BILLING_TYPES[0];
      this.price = price;
      this.pricePeriod = PRICE_PERIODS.includes(pricePeriod) ? pricePeriod : PRICE_PERIODS[0];
      this.benefits = benefits;
      this.released = released;
      this.releaseDate = releaseDate;
      this.contactPhone = contactPhone;
    }
  }

  export const avaiablePlansList = [
    new Plan(1, 'Base', undefined, 'annually', undefined, 'month', 
             ['Letter of Credit', 'Standby Letters of Credit', 'Documentary Collection', 'Ability to scale via cloud based subscription mode'], 
             true, undefined, undefined),
    new Plan(2, 'Premium', 'Expanded suite of Trade Finance Offerings', 'annually', undefined, 'month', undefined, false, new Date(2022, 4, 1), process.env.REACT_APP_CONTACT_PHONE),
    new Plan(3, 'Ultra', 'Full suite of Trade Finance Offerings', 'annually', undefined, 'month', undefined, false, new Date(2022, 4, 1), process.env.REACT_APP_CONTACT_PHONE)
  ]

  export const termsOfServiceObj = {
    title: 'Terms and Conditions',
    description:
      "We'd like to set Analytics  tracking cookies to help us improve our website by collecting and reporting information on how you use it. The cookies collect information in a way that does not directly identify anyone.",
      confirmationButtonLabel: 'Yes, Accept', 
      cancellationButtonLabel: 'No, Cancel',
  }