import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import './TermsOfServiceModal.scss';
import {getCookie, deleteCookie, setCookie} from '../../../src/utilities/Cookies'


function TermsOfService( { 
  title,
  description,
  confirmationButtonLabel,
  cancellationButtonLabel,
}) {
  
  const [showModal, setShow] = useState(false);

   
  useEffect(()=>{
    const hideModal = getCookie('hide-modal');
    if (hideModal === 'true'){
      setShow(false);
    } else {
      if(navigator.cookieEnabled) setShow(true);
    }
  }, []);
  

  function allowAnalytics() {
    setCookie("opt-out", 1);
    deleteCookie("hide-modal");
    setCookie("hide-modal", 1);
    setShow(false);
    window.location.reload();
  }

  function denyAnalytics() {
    deleteCookie("opt-out");
    deleteCookie("hide-modal");
    setCookie("hide-modal", 1);
    setShow(false);
  } 

  return (
    <>
  
      <Modal backdrop = {'static'} keyboard = {false} show={showModal} onHide={() => {denyAnalytics();}}>
        <Modal.Header>
          <Modal.Title className = 'terms_title'>{title}</Modal.Title>          
        </Modal.Header>
        <Modal.Body>{description}</Modal.Body>
        <Modal.Footer data-backdrop="static">
          <button className = 'cancel__button' onClick={ () => { 
            denyAnalytics();
          }}>
            {cancellationButtonLabel}
          </button>
          <button className = 'confirmation__button' onClick={() => {
            allowAnalytics();
          }}>
            {confirmationButtonLabel}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TermsOfService;

