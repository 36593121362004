import React from 'react';
import {
  coverPageObj, 
  videoPageObj,
  planPageObj, 
  infoPageObj01,
  infoPageObj02,
  infoPageObj03,
  formSectionObj, 
  videoSectionObj,
  calendlyUrlObj,
  footerSectionObj,
  termsOfServiceObj
} from './Data';

import CoverSection from '../../components/CoverSection/CoverSection';
import VideoSection from '../../components/VideoSection/VideoSection';
import PlanSection from '../../components/PlanSection/PlanSection';
import FormSection from '../../components/FormSection/FormSection';
import InfoSection from '../../components/InfoSection/InfoSection';
import FooterSection from '../../components/FooterSection/FooterSection';
import TermsOfService from '../../components/TermsOfService/TermsOfServiceModal';

function Home() {
  const scrollRef = React.createRef();
  function scrollToSection(selectedPlan) {
    scrollRef.current.scrollIntoView({ behavior: 'smooth' })
    if(selectedPlan){
      var formElements = document.getElementById("hs-form-iframe-0")
        .contentDocument.querySelector('select[name$="plan"]');
      if(formElements) {
        formElements.value = selectedPlan;
      }
    }
  };

  return (
    <>
      <CoverSection 
        {...coverPageObj} 
        url={calendlyUrlObj.url} 
        refFunction={scrollToSection} />
      <InfoSection {...infoPageObj01} />
      <InfoSection {...infoPageObj02} />
      <InfoSection {...infoPageObj03 } />
      <VideoSection 
        {...videoPageObj} 
        embedId={videoSectionObj.embedId} 
        refFunction={scrollToSection} />
      <PlanSection 
        {...planPageObj} 
        refFunction={scrollToSection} />
      <FormSection 
        portalId={formSectionObj.portalId} 
        formId={formSectionObj.formId} 
        formTitle={formSectionObj.formTitle}
        ref={scrollRef} />
      <FooterSection {...footerSectionObj}/>
      <TermsOfService {...termsOfServiceObj}/>
      
    </>
  );
}




export default Home;