import React from 'react';
import './VideoSection.scss';
import { TalkSpecialistButton } from '../TalkSpecialistButton/TalkSpecialistButton';
import YoutubeEmbed from '../YoutubeEmbed/YoutubeEmbed';

function VideoSection({
  headline,
  description,
  buttonLabel,
  embedId,
  refFunction
}) {
  return (
    <>
      <div className='video-section'>
        <div className='section-container'>
          <div className='row__video video-section__text-wrapper'>
            <h1 className='heading'>
              {headline}
            </h1>
            <div className='row__video subtitle-wrapper'>
              <p className='subtitle'>
                {description}
              </p>
            </div>
          </div>
          <div className='row__video video-section__content-wrapper'>
            <div className='video-wrapper--youtube'>
              <YoutubeEmbed embedId={embedId} />
            </div>
          </div>
          <div className='row__video video-section__button-wrapper'>
            <TalkSpecialistButton
              btnStyle={'btn-talk--secondary_page'}
              btnText={buttonLabel}
              refFunction={refFunction}
            >
            </TalkSpecialistButton>
          </div>
        </div>
      </div>
    </>
  );
}

export default VideoSection;