import React from "react";
import './FooterSection.scss'

function FooterSection ({
    headline,
    description,
    image
}) {
    return (
        <>
            <div className='background__footer-section'>
                <div className='home__footer-section' style={{ 
                    backgroundImage: `url(${image})`}}> 
                    <div className='footer__container'>   
                        <div className='footer__text'>
                            <h1 className='footer__title'>
                                {headline}
                            </h1>
                            <div className='footer__description'>
                                {description}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FooterSection;