import React, { useState } from 'react';
import './PlanSection.scss';
import PlanCard from '../PlanCard/PlanCard';
import { avaiablePlansList } from './../../pages/HomePage/Data';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function PlanSection({
  headline,
  description,
  buttonLabel,
  refFunction,
  selectedPlan
}) {
  const descriptionList = description ? description.split('\n') : [];
  const planNames = avaiablePlansList.map(function(p) { return p.planName; });
  
  const getIndexOfPlan = (planName) => { 
    return planName && planNames.includes(planName)
      ? planNames.indexOf(planName) 
      : Math.floor(planNames.length / 2)
  };

  const [defaultPlan, setDefaultPlan] = useState(getIndexOfPlan(selectedPlan));

  const activePlanFunction = (activatedPlan) => {
    setDefaultPlan(getIndexOfPlan(activatedPlan));
  };

  return (
    <>
      <div className='plan-section'>
        <div className='section-container'>
          <div className='row_plan plan-section__text-wrapper'>
            <h1 className='heading'>
              {headline}
            </h1>
            <div className='row_plan subtitle-wrapper'>
              <p className='subtitle'>
                { 
                  descriptionList.map(function(descriptionItem, index){
                    if (index > 0) {
                      return <span key={'desc-' + index} className='new-line'>{descriptionItem}</span>;
                    } else {
                      return <span key={'desc-' + index}>{descriptionItem}</span>;
                    }
                  })
                }
              </p>
            </div>
          </div>
          <div className='row_plan full-screen plan-section__content-wrapper'>
            { 
              avaiablePlansList.map(function(planObj, index){
                return <PlanCard 
                          {...planObj} 
                          key={'plan-' + index} 
                          buttonLabel={buttonLabel} 
                          refFunction={refFunction} 
                          activatedPlan={index === defaultPlan}
                          activePlan={activePlanFunction}
                          fixedWidth={Math.floor(100 / avaiablePlansList.length)}
                       />;
              })
            }
          </div>
          <div className='row_plan mobile-screen plan-section__content-wrapper' >
             <Carousel 
              selectedItem={1}
              centerSlidePercentage={100}
              showThumbs={false}
              centerMode={true}
              showStatus={false}
              swipeScrollTolerance={25}
              >
              { 
                avaiablePlansList.map(function(planObj, index){
                  return <div class='carousel__div'>
                        { <PlanCard 
                            {...planObj} 
                            key={'plan-' + index} 
                            buttonLabel={buttonLabel} 
                            refFunction={refFunction} 
                            activatedPlan={index === defaultPlan}
                            activePlan={activePlanFunction}
                            fixedWidth={150}
                        /> }
                  </div>
                })
              }
            </Carousel> 
          </div>
        </div>
      </div>
    </>
  );
}

export default PlanSection;
