import React from 'react';
import './FormSection.scss';

class FormSection extends React.Component {
 
  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);
    
    script.addEventListener('load', () => {
    	if(window.hbspt) {
      	window.hbspt.forms.create({
        	region: "na1",
          portalId: this.props.portalId,
          formId: this.props.formId,
          target: '.div__form'
        });
      }
    });
  }

  render() {
    return (
      <>
        <div ref={this.props.innerRef} className='form__background form__container'>
            <div className='form__title'>
              <h1>{this.props.formTitle}</h1>
            </div>
            <div className='form__frame'>
                <div className='div__form'></div>
            </div>
        </div>
      </> 
    );
  }

 }

export default React.forwardRef((props, ref) => <FormSection 
  innerRef={ref} {...props}
/>);
