import React from 'react';
import './PlanCard.scss';
import { TalkSpecialistButton } from '../TalkSpecialistButton/TalkSpecialistButton';

function PlanCard({
  planId, 
  planName, 
  description, 
  benefits, 
  released, 
  releaseDate, 
  contactPhone,
  activatedPlan,
  buttonLabel, 
  refFunction,
  activePlan,
  fixedWidth
}) {
  const btnStyle = activatedPlan ? 'btn-talk--tertiary' : 'btn-talk--primary';
  const cardStyle = activatedPlan ? 'plan-card-item--active' : 'plan-card-item--inactive';
  const imgMark = activatedPlan ? '/check-mark-active.svg' :  '/check-mark-inactive.svg';
  const releaseMonth = releaseDate ? releaseDate.toString().slice(4,7) + ' ' + releaseDate.getFullYear() : '';
  
  const clickButtonFunction = (parameter) => {
    activePlan(planName);
    refFunction(parameter);
  }

  return (
    <>
      <div className={'plan-card-item ' + cardStyle} style={{width: 'calc(' + fixedWidth + '% - 20px)'}} onClick={() => { activePlan(planName) }} >
        <div className='card-container'>
          <div className='row plan-card-item__text-wrapper'>
            <h1 className='heading'>
              {planName}
            </h1>
            <div className='row subtitle-wrapper'>
              <p className='subtitle'>
                {description}
              </p>
            </div>
          </div>
          <div className='row plan-card-item__content-wrapper'>
            <div className='plan-benefits'>
              { 
                benefits.map(function(benefit, index){
                  return <div className={'benefit' + (index !== benefits.length - 1 ? '' : ' last-item')} key={'bnf-' + index}>
                            <img className='mark_icon' src={imgMark} alt= '-'/>
                            <div className='benefit-highlighted'>
                              {
                                markText(benefit).map(function(benefitHighlighted, indexMark){
                                  if(benefitHighlighted.marked)
                                    return <strong key={'bnf-hgh-' + indexMark}>{benefitHighlighted.text}</strong>;
                                  return benefitHighlighted.text;
                                })
                              }
                            </div>
                        </div>;
                })
              }
            </div>
            { 
              released 
                ? (<></>)
                : (
                    <div className='plan-release-alert'>
                      <div className='plan-release-alert__release-month'>Coming {releaseMonth}</div>
                      <div className='plan-release-alert__contact-phone'>Call {contactPhone} to learn more.</div>
                    </div>
                  )
            }
          </div>
          <div className='row plan-card-item__button-wrapper'>
            <div className='align-buttons-wrapper'>
              <TalkSpecialistButton
                btnStyle={btnStyle}
                btnText={buttonLabel}
                refFunction={clickButtonFunction}
                functionParam={planId}
              ></TalkSpecialistButton>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function markText(annotatedText, markIn='[', markOut=']') {    
  let markedTextsList = [];
  let currentText = '';
  let marked = false;
  for (let i = 0; i < annotatedText.length; i++) {
    if (annotatedText[i] === markIn && !marked) {
      if (currentText.length > 0)
        markedTextsList.push({
            'text': currentText,
            'marked': marked
          });
      currentText = '';
      marked = true;
    } else if (annotatedText[i] === markOut && marked) {
      if (currentText.length > 0)
        markedTextsList.push({
            'text': currentText,
            'marked': marked
          });
      currentText = '';
      marked = false;
    } else {
      currentText += annotatedText[i];
    }  
  }
  
  if (currentText.length > 0) {
    if (marked)
      currentText = markIn + currentText
    markedTextsList.push({
      'text': currentText,
      'marked': false
    });
  }

  return markedTextsList;
}

export default PlanCard;
