import React from 'react';
import './CoverSection.scss';
import { TalkSpecialistButton } from '../TalkSpecialistButton/TalkSpecialistButton';
import { calendlyObj } from '../../pages/HomePage/Data';
import ScheduleButton from '../ScheduleButton/ScheduleButton';
import { LogoImage } from '../LogoImage/LogoImage';

function CoverSection({
  title,
  subtitle,
  description,
  headerButtonLabel,
  buttonLabel,
  refFunction,
  url,
  backgroundImg
}) {

  return (
    
    <> 
    <div 
      className='home__cover-section font-white cover__backgroud'
      style={{ backgroundImage: `url(${backgroundImg})`}}>
      <div className='cover__container'>
        <div className='cover__header'>
            <LogoImage isFooter={false}/>
            <ScheduleButton {...calendlyObj} url={url} headerButtonLabel={headerButtonLabel}/>
          </div>
          <div className='cover__row'>
            <div className='col'>
              <div className='home__cover-text-wrapper'>
                <h1 className='cover__title'>
                  {title}
                </h1>
                <span className='cover__subtitle'>
                  {subtitle}
                </span>
                <p className='cover__description'>
                  {description}
                </p>
                <TalkSpecialistButton 
                  btnStyle={'btn-talk--primary'}
                  btnText={buttonLabel} 
                  refFunction={refFunction}
                />
                <ScheduleButton {...calendlyObj} url={url} headerButtonLabel={headerButtonLabel}/>
              </div>
            </div>
          </div>
      </div>
    </div>
    </>
  );
}

export default CoverSection;